import { FaBuilding } from "react-icons/fa"

export default {
  name: "condominio",
  label: "Condomínio",
  icon: FaBuilding,
  tags: "condominio, condominios, predios,",
  link: "https://basisseguros.aggilizador.com.br/condominium",
  shortDescription:
    "O seguro obrigatório do seu condomínio com as coberturas necessárias. Assistências 24 horas para serviços de vigia, chaveiro para apartamentos, porteiro substituto e muito mais!",
  productName: "Seguro Condomínio",
  pageDescription:
    "O seguro obrigatório para condomínios verticais ou horizontes, residenciais, comerciais ou mistos, onde terá total amparo para os danos materiais sofridos em sua estrutura, instalações elétricas, hidráulicas e partes comuns.",
  features: [
    {
      icon: "build-alt",
      title: "COBERTURA BÁSICA SIMPLES",
      text:
        "Tipo de cobertura onde o condomínio poderá contar com coberturas como incêndio, queda de raio e explosão de qualquer natureza.",
    },
    {
      icon: "build",
      title: "COBERTURA BÁSICA AMPLA",
      text:
        "A cobertura completa onde pode causar qualquer tipo de danos materiais ao condomínio.",
    },
    {
      icon: "factory",
      title: "COBERTURAS ADICIONAIS",
      text:
        "As coberturas adicionais para toda a área do condomínio como, acidente pessoais dos empregados, alagamento e inundação, danos elétricos, equipamentos eletrônicos, quebra de vidros, responsabilidade civil síndico, responsabilidade civil condomínio, etc.",
    },
    {
      icon: "clock",
      title: "SERVIÇOS DE ASSISTÊNCIAS",
      text:
        "Pode contar com as assistências 24 horas, onde terá a tranquilidade e a segurança de profissionais altamente qualificados para reparos como chaveiro, eletricista, limpeza, reparo de antenas, segurança e vigilância, zelador substituto, segurança e vigilância, etc.",
    },
  ],
}
