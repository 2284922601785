import Auto from "./auto"
import Residencial from "./residencial"
import Condominio from "./condominio"
import Empresarial from "./empresarial"
import PlanoDeSaude from "./planoDeSaude"
import Vida from "./vida"
import Transporte from "./transporte"
import Diversos from "./diversos"

export default [
  Auto,
  Residencial,
  Condominio,
  Empresarial,
  PlanoDeSaude,
  Vida,
  Transporte,
  Diversos,
]
