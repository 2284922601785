import React from "react"

import { Container } from "./styles"

export default ({
  children,
  size = "normal",
  theme = "light",
  lineHeight = "default",
}) => (
  <Container size={size} theme={theme} lineHeight={lineHeight}>
    {children}
  </Container>
)
