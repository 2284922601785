import styled from "styled-components"

const green = "#52b43e"

export const Container = styled.div`
  background: #fefefe;
  border-radius: 0.5rem;
  bottom: 100%;
  box-shadow: 0px 1rem 1rem rgba(0, 0, 0, 0.3);
  font-family: "Open Sans", sans-serif;
  margin-bottom: 3rem;
  position: absolute;
  overflow: hidden;
  right: 0;
  width: 30rem;
  max-width: 80vw;
  z-index: 50;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  transform: translateY(380px);

  ${({ status }) =>
    status &&
    `
    opacity: 1;
    transform: translateY(0);
  `}
`

export const Header = styled.div`
  background-color: ${green};
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  padding: 1.5rem 1rem;

  svg {
    margin-right: 1rem;
  }
`

export const Link = styled.a`
  align-items: center;
  color: #666;
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 700;
  padding: 1.5rem 0;
  text-transform: uppercase;

  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }

  svg {
    color: ${green};
    font-size: 2rem;
    margin-right: 0.5rem;
  }
`
