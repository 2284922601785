import React from "react"

import { Container, NavbarBurger } from "./styles"

import HeaderLogo from "../../images/header-logo.svg"
import Navbar from "./Navbar"

import { FaBars } from "react-icons/fa"
import { useState } from "react"

import HeaderContext from "./context"

export default function Header() {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <Container>
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-3-desktop is-full-tablet logo">
            <img src={HeaderLogo} alt="Basis Seguros" />
          </div>

          <NavbarBurger
            className="is-hidden-desktop"
            onClick={() => setShowMenu(!showMenu)}
          >
            <FaBars />
          </NavbarBurger>
          <HeaderContext.Provider value={{ showMenu }}>
            <Navbar />
          </HeaderContext.Provider>
        </div>
      </div>
    </Container>
  )
}
