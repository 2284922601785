import { FaCar } from "react-icons/fa"

export default {
  name: "auto",
  label: "Auto",
  icon: FaCar,
  tags: "auto, carros, caminhões, veículos",
  productName: "Seguro Auto",
  link: "https://basisseguros.aggilizador.com.br/auto",
  shortDescription:
    "A melhor proteção para você e seu veículo, com coberturas necessárias para todos os imprevistos. Disponível para carros, motos, caminhões e frotas.",
  features: [
    {
      icon: "user-shield",
      title: "COBERTURA TOTAL PARA O SEU VEÍCULO E TERCEIROS (COMPRENSIVO)",
      text:
        "Esteja com o seu veículo totalmente garantido contra colisão, roubo/fruto, incêndio, perda total e principalmente, de sua responsabilidade com TERCEIROS. Com a contratação de coberturas compatíveis com o mercado, evitando assim dores de cabeças com prejuízos à terceiros.",
    },
    {
      icon: "users",
      title: "DANOS À TERCEIRO (RCF)",
      text:
        "Um seguro para você, que tem responsabilidade com a sociedade, com coberturas compatíveis, que em caso de acidentes com envolvimentos de terceiros, terá cobertura total para o mesmo.",
    },

    {
      icon: "danger",
      title: "PERDA TOTAL POR ROUBO/ FURTO OU COLISÃO/DANOS",
      text:
        "Caso seu veículo seja roubado, colidido durante o roubo, e os reparos atingirem 75% valor de acordo com o tabela FIPE, você será indenizado em 100%.",
    },
    {
      icon: "user-thief",
      title: "ROUBO OU FURTO TOTAL",
      text:
        "Para você, que não quer ficar totalmente desprotegido e evitar a perda do seu patrimônio.",
    },

    {
      icon: "car-side",
      title: "CARRO RESERVA",
      text:
        "Em caso de sinistro coberto e mediante a contratação, tenha a locação de um veículos por 7, 15 ou 30 dias ou enquanto os reparos sejam realizados.",
    },
    {
      icon: "car",
      title: "COBERTURA DE VIDROS, FAROIS, LANTERNAS E RETROVISORES",
      text:
        "Cobertura que garante a reposição ou reparo mediante ao pagamento de uma franquia menor que a franquia principal do véiculo.",
    },
    {
      icon: "key",
      title: "CHAVEIRO",
      text:
        "Caso perda as chaves do seu veículo ou as tranque dentro do mesmo, terá total assistência para abertura do mesmo e assim poder continuar seu trajeto.",
    },
    {
      icon: "tow-truck",
      title: "ASSISITENCIA 24 HORAS (GUINCHO)",
      text:
        "Assistencia 24 horas por dia, com cobertura básica para você que não costume viajar para longas distancias e para você que não há limites atendimento em todo território nacional, e ainda pode ter assistência pera o Mercosul mediante a contratação. ",
    },
    {
      icon: "flat-tire",
      title: "TROCA DE PNEUS",
      text:
        "Caso o pneu do seu veículo fure, murche ou estoure, poderá solicitar um atendimento com um prestador autorizado pela seguradora, assim você terá uma troca segura.",
    },
    {
      icon: "cab",
      title: "TÁXI",
      text:
        "Em casos de sinistro ou pane com o seu veículo, a seguradora poderá disponibilizar um prestador para que você e seus ocupantes continuam com o seu trajeto ou voltar para o seu local de origem.",
    },
  ],
}
