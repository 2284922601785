import { FaIndustry } from "react-icons/fa"

export default {
  name: "empresarial",
  label: "Empresarial",
  icon: FaIndustry,
  link: "https://basisseguros.aggilizador.com.br/business",
  tags:
    "empresa, empresarial, companhias, incêndio, explosão, danos elétricos, equipamentos, lucros cessantes, despesas fixas",
  shortDescription:
    "Mais tranquilidade para sua empresa. Coberturas para incêndio, explosão, danos elétricos, equipamentos, lucros cessantes, despesas fixas, etc., além de assistências 24h.",
  productName: "Seguro Empresarial",
  pageDescription:
    "O seguro obrigatório para condomínios verticais ou horizontes, residenciais, comerciais ou mistos, onde terá total amparo para os danos materiais sofridos em sua estrutura, instalações elétricas, hidráulicas e partes comuns.",
  features: [
    {
      icon: "shield",
      title: "COBERTURAS ADICIONAIS",
      text:
        "Seu estabelecimento ainda pode contar com coberturas adicionais como alagamento e inundações, dano elétricos, anúncios luminosos, quebra de máquinas, impacto de veículos, responsabilidade civil, lucros cessantes, despesas fixas, etc.",
    },
    {
      icon: "clock",
      title: "ASSISTÊNCIAS 24 HORAS",
      text:
        "Poderá contar com mais de 20 assistências 24 horas, para que seu negócio não pare. Assistências como encanador e eletricista, reparos em ar condicionados, vigilância, chaveiro, cobertura provisório de telhados, etc.",
    },
  ],
}
