import { FaUser } from "react-icons/fa"

export default {
  name: "vida",
  label: "Vida",
  icon: FaUser,
  tags: "vida, falescimento",
  link: "https://basisseguros.aggilizador.com.br/life",
  shortDescription:
    "Proteja sua renda e de sua família em todos os momentos. Coberturas personalizadas para situações de doenças graves, acidentes ou falecimento.",
  productName: "Seguro de Vida",
  features: [
    {
      icon: "user-shield",
      title: "VIDA INDIVIDUAL",
      text:
        "Nos momentos mais difíceis da vida, não há como mensurar a perda de uma pessoa querida. Porém, com o seguro de vida individual você terá um amparo financeiro para não se preocupar por algum tempo.",
    },
    {
      icon: "user-sick",
      title: "ACIDENTE PESSOAL (AP)",
      text:
        "Produto ideal para os imprevistos com acidentes, para invalidez permanente ou parcial e até mesmo para caso de morte acidental. Mesmo você não podendo exercer sua função, poderá ter a sua renda garantida.",
    },
    {
      icon: "user-suite",
      title: "EMPRESARIAL",
      text:
        "Um benefício ao colaborador, onde os familiares irão se sentir amparado pelo empregador no pior momento da vida.",
    },
    {
      icon: "plan",
      title: "VIAGEM",
      text:
        "Em caso de alguma doença ou acidente em qualquer tipo de viagem, você terá total suporte para poder ser atendido e continuar curtindo sua viagem.",
    },
    {
      icon: "house",
      title: "PRESTAMISTA",
      text:
        "Garantia de quitação do seu financiamento imobiliário em caso de morte ou até mesmo de invalidez permanente.",
    },
    {
      icon: "badge-alt",
      title: "ESTAGIÁRIO",
      text:
        "O seguro obrigatório para o seu estagiário, evitando assim dores de cabeças e garantido a lei de contratação.",
    },
  ],
}
