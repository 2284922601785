import { FaHome } from "react-icons/fa"

export default {
  name: "residencial",
  label: "Residencial",
  icon: FaHome,
  link: "https://basisseguros.aggilizador.com.br/residence",
  tags:
    "residencia, chaveiro, encanador, eletricista, reparos eletrodomésticos, residencial, casa",
  shortDescription:
    "Seu lar protegido com coberturas necessárias e ainda conta com assistências emergenciais 24h, como chaveiro, encanador, eletricista, reparos de eletrodomésticos muito mais!",
  productName: "Seguro Residencial",
  features: [
    {
      icon: "extinguisher",
      title: "COBERTURA INCÊNDIO, RAIO, EXPLOSÃO E FUMAÇA",
      text:
        "Garante a indenização do seu imóvel contra uma explosão inesperada de um botijão de gás, de um aquecedor de água a gás ou até mesmo de uma panela de pressão. Cobertura de fumaça proveniente, exclusivamente, de desarranjo imprevisível, repentino e extraordinário no funcionamento de qualquer aparelho, máquina ou forno existentes no local segurado, desde que os mesmos estejam conectados a uma chaminé por meio de um cano condutor de fumo.",
    },
    {
      icon: "lightning",
      title: "DANOS ELÉTRICOS",
      text:
        "Garante as perdas ou danos físicos diretamente causados a quaisquer máquinas, equipamentos ou instalações eletrônicas ou elétricas devido a variações anormais de tensão, curto-circuito, calor gerado acidentalmente por eletricidade, descargas elétricas, eletricidade estática ou qualquer efeito ou fenômeno de natureza elétrica, inclusive a queda de raio ocorrida fora do local segurado.",
    },
    {
      icon: "group",
      title: "RESPONSABILIDADE CIVIL FAMILIAR",
      text:
        "Uma cobertura que garante reembolso aos seus segurados, pelos danos materiais e corporais causado a terceiros, como objetos lançados pela janela, ações de seus filhos menores sob sua responsabilidade, cobertura de empregados domésticos, desde que o mesmo esteja em suas obrigações no imóvel segurado, mesmo que ocorrido nas áreas externa do imóvel. Cobertura por qualquer tipo de danos causa por seu animal de estimação",
    },
    {
      icon: "user-ninja",
      title: "SUBTRAÇÃO DE BENS (ROUBO)",
      text:
        "Garante ao segurado, até o limite contratado, os bens roubados ou furtados do imóvel segurado.",
    },
    {
      icon: "money-hand",
      title: "PERDA OU PAGAMENTO DE ALUGUEL",
      text:
        "Em caso de sinistro, que você segurado tenha que ficar fora do seu imóvel para que o mesmo possa ser reconstituído, essa cobertura garante o pagamento do aluguel até o valor contratado.",
    },
    {
      icon: "drop",
      title: "VAZAMENTO DE TUBULAÇÕES ",
      text:
        "Garante à você segurado, até o limite contratado, aquela vazamento indesejado no seu vizinho do apartamento de baixo ou do lado, por tubulações corrompidas.",
    },
    {
      icon: "house-crack",
      title: "QUEBRA DE VIDROS ",
      text:
        "Garante ao segurado, até o limite contratado, quebra de vidros causados imprudentemente ou por culpa de terceiros, por atos involuntários do segurado, membros da sua família ou até mesmo por seus empregados e danos causados por chuva de granizo.",
    },
    {
      icon: "clock",
      title: "ASSISTÊNCIAS 24 HORAS ",
      text:
        "Serviços de encanador, eletricista, chaveiro, limpeza de calhas, lavagem de caixa d’agua, help desk, reparos em máquina de lavar roupa, geladeira, fogão e mais de 20 tipos de assistências com dia e hora e marcada e emergenciais. ",
    },
    {
      icon: "house",
      title: "SEGURO IMOBILIÁRIO (RESIDENCIAL) ",
      text:
        "O produto correto para quem está locando um imóvel para moradia ou para sua empresa, onde o contrato de locação lhe obriga da contratação do seguro contra incêndio, explosão e fumaça, com cláusula beneficiando o proprietário do imóvel. Com a possibilidade de contratar coberturas adicionais como danos elétricos, responsabilidade civil, impacto de veículos, etc. Pode contar com a famosa assistência 24 horas de reparos elétricos, reparos hidráulicos, chaveiro, reparos em linha branca, etc.",
    },
  ],
}
