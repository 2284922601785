import styled from "styled-components"

import colors from "../../../styles/colors"

import { lighten } from "polished"

export const Container = styled.div`
  @media (max-width: 823px) {
    display: none;

    &.is-active {
      display: initial;
    }
  }
`

export const Nav = styled.nav`
  height: 100%;

  .navbar {
    @media (min-width: 824px) {
      &-start {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }

    &-item,
    &-link {
      color: ${colors.grey2};
      font-size: 1.5rem;
      font-weight: 700;
      height: initial;
      padding: 0;
      text-transform: uppercase;

      &:hover {
        background-color: transparent;
        color: ${colors.blue1};
      }

      &.has-dropdown {
        .navbar-link {
          &:hover {
            background-color: transparent;
            color: ${colors.blue1};
          }
        }

        &.is-active {
          .navbar-link {
            background-color: initial;
            color: ${colors.blue1};
          }
        }
      }
    }

    @media (min-width: 824px) {
      &-dropdown {
        border-bottom: 0.4rem solid ${colors.blue1};
        border-radius: 0;
        border-top: none;
        box-shadow: 0 0 0 0;
        padding: 1rem 2rem;

        .navbar-item {
          color: ${colors.grey3};
          font-size: 1.4rem;
          font-weight: 400;
          padding: 0.5rem 1rem;
          width: 19rem;

          &:hover {
            color: ${colors.blue1};
          }

          &:not(:last-child) {
            border-bottom: 0.1rem solid ${colors.grey1};
          }
        }
      }
    }

    @media (max-width: 823px) {
      &-item,
      &-link {
        padding: 0.5rem 0;
        text-align: center;
      }

      &-dropdown {
        .navbar-item {
          color: ${lighten(0.1, colors.grey3)};
        }
      }
    }
  }
`
