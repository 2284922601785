import styled from "styled-components"

import colors from "../../styles/colors"

export const Container = styled.header`
  background-color: white;
  border-top: 0.5rem solid ${colors.blue1};
  box-shadow: 0 0.2rem 0.2rem rgba(130, 132, 133, 0.6);
  background-color: white;
  padding: 2rem 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  @media (max-width: 823px) {
    position: initial;

    .logo {
      text-align: center;
    }
  }
`
export const NavbarBurger = styled.div`
  font-size: 25px;
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 100;
`
