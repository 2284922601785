import styled from "styled-components"

import colors from "../../styles/colors"

export const Container = styled.footer`
  margin-top: 7rem;
`

export const Contact = styled.div`
  background-color: ${colors.blue2};
  padding: 7rem 0;
`
export const List = styled.div`
  margin-top: 4.5rem;
`

export const Item = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  svg {
    color: ${colors.green1};
    font-size: 2.4rem;
    margin-right: 1rem;
  }
`

export const Info = styled.div`
  color: white;
  font-size: 1.8rem;

  a {
    color: white;
    font-weight: 700;
  }

  strong {
    color: white;
    display: block;
  }
`
export const Map = styled.div`
  overflow: hidden;
  padding-bottom: 110%;
  position: relative;
  height: 0;
  margin-top: 2rem;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`

export const Raes = styled.div`
  align-items: center;
  background-color: #052e39;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`
