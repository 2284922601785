import styled from "styled-components"

import colors from "../../../styles/colors"

export const Container = styled.form`
  margin-top: 4rem;

  .field {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  .label {
    color: ${colors.green1};
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .input,
  .textarea {
    border: 0.1rem solid ${colors.blue1};
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0.5);
    color: ${colors.grey3};
    font-family: "Josefin Sans", sans-serif;
    font-size: 1.5rem;
    padding: 0.5rem 2rem;

    &::placeholder {
      color: ${colors.grey3};
    }
  }
`

export const Button = styled.button`
  background-color: ${colors.green1};
  box-shadow: 0.1rem 0.1rem 0.2rem ${colors.grey3};
  color: white;
  font-family: "Josefin Sans", sans-serif;
  border: none;
  border-radius: 0;
  color: white;
  font-size: 1.8rem;
  font-weight: 700;
  padding: 1rem 6.5rem;
  text-transform: uppercase;
`
