import React from "react"
import PropTypes from "prop-types"

import Header from "./Header"

import GlobalStyle from "../styles/global"
import Footer from "./Footer"
import WhatsApp from "../components/Whatsapp"

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Header />
      <main>{children}</main>
      <Footer />
      <WhatsApp />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
