import styled from "styled-components"

import colors from "../../styles/colors"

export const Container = styled.div`
  margin-bottom: 4rem;
  text-align: center;

  h1 {
    color: ${colors.blue2};
    display: inline-block;
    font-size: 3.5rem;
    font-weight: 700;
    text-transform: uppercase;

    &::after {
      background-color: ${colors.green1};
      content: "";
      display: block;
      height: 0.4rem;
      width: 10rem;
    }

    &.light {
      color: white;
    }
  }

  @media (max-width: 450px) {
    margin-bottom: 2rem;

    h1 {
      font-size: 2.5rem;
    }
  }
`
