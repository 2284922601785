import React from "react"

import { Container, Contact, List, Item, Info, Map, Raes } from "./styles"

import LogoRaes from "../../images/logo-raes.svg"

import Title from "../Title"
import Text from "../Text"

import {
  FaWhatsapp,
  FaEnvelope,
  FaMapMarkerAlt,
  FaAddressCard,
} from "react-icons/fa"
import { useStaticQuery, graphql } from "gatsby"

import { formatPhone } from "../../utils/phone"

import Form from "./Form"

export default () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          whatsapp
          email
        }
      }
    }
  `)

  const whatsapp = data.site.siteMetadata.whatsapp
  const email = data.site.siteMetadata.email

  return (
    <Container>
      <Contact>
        <div id="contato" className="container">
          <div className="columns is-centered">
            <div className="column is-6-desktop has-text-centered">
              <Title label="Contato" theme="light" />
              <Text theme="light" size="big">
                Deseja conhecer melhor nossos serviços, tirar alguma dúvida ou
                fazer uma cotação? Entre em contato conosco pelo WhatsApp!
              </Text>
              <Text theme="light" size="big">
                Se preferir, entre em contato pelo formulário abaixo.
              </Text>
            </div>
          </div>

          <div className="columns is-centered">
            <div className="column is-4-desktop is-half">
              <List>
                <Item>
                  <FaAddressCard />
                  <Info>SUSEP: 202105015</Info>
                </Item>
                <Item>
                  <FaWhatsapp />
                  <Info>
                    <a
                      href={`https://wa.me/55${whatsapp}`}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      {formatPhone(whatsapp)}
                    </a>
                  </Info>
                </Item>
                <Item>
                  <FaEnvelope />
                  <Info>
                    <a
                      href={`mailto:${email}`}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      {email}
                    </a>
                  </Info>
                </Item>
                <Item>
                  <FaMapMarkerAlt />
                  <Info>
                    <strong>Av. Pasteur, 374 - Conj. 143</strong>
                    Vila Matilde | São Paulo/SP
                  </Info>
                </Item>
              </List>

              <Map>
                <iframe
                  title="Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.7508690488794!2d-46.52057538454674!3d-23.541461284692748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5e01812066bd%3A0xca948648df683afd!2sAv.%20Pasteur%2C%20374%20-%20Vila%20Nova%20Savoia%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2003531-000!5e0!3m2!1spt-BR!2sbr!4v1593973059931!5m2!1spt-BR!2sbr"
                  width="600"
                  height="450"
                  frameborder="0"
                  allowfullscreen=""
                  aria-hidden="false"
                ></iframe>
              </Map>
            </div>

            <div className="column is-4-destop is-half">
              <Form />
            </div>
          </div>
        </div>
      </Contact>
      <Raes>
        <img src={LogoRaes} alt="Raes Web Stúdio" />
      </Raes>
    </Container>
  )
}
