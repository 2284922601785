import React, { useState } from "react"

import { Button, Container } from "./styles"

import { formatPhone } from "../../../utils/phone"
import { stubTrue } from "lodash"

function Form() {
  const initialForm = {
    nome: null,
    email: null,
    telefone: null,
    mensagem: null,
  }

  const [formData, setFormData] = useState(initialForm)

  const [formResult, setFormResult] = useState({
    show: false,
    type: null,
    message: null,
  })

  const updateFormData = (label, value) => {
    if (label === "telefone" && value.length > 0) {
      const x = value.replace(/\D/g, "").match(/(\d{0,2})(\d{0,5})(\d{0,4})/)

      setFormData({
        ...formData,
        telefone: !x[2]
          ? x[1]
          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : ""),
      })
      return
    }

    setFormData({ ...formData, [label]: value })
  }

  const submitForm = async event => {
    event.preventDefault()

    const data = new FormData()

    Object.keys(formData).map(key => data.append(key, formData[key]))

    const request = await fetch("//basisseguros.com.br/send-form.php", {
      method: "POST",
      body: data,
    })

    const resp = await request.json()

    if (resp.success) {
      setFormData(initialForm)
      setFormResult({ show: true, type: "success", message: resp.message })
      // trackCustomEvent({ category: "Form", action: "send" })
      return
    }

    setFormResult({
      show: true,
      type: "error",
      message: "Erro ao enviar o formulário tente novamente",
    })
  }

  return (
    <Container onSubmit={event => submitForm(event)}>
      <div className="field">
        <label htmlFor="name" className="label">
          Nome
          <div className="control">
            <input
              type="text"
              id="name"
              name="nome"
              className="input"
              placeholder="Seu nome"
              onChange={({ target }) => updateFormData("nome", target.value)}
              value={formData.nome}
              required
            />
          </div>
        </label>
      </div>

      <div className="field">
        <label htmlFor="phone" className="label">
          Telefone
          <div className="control">
            <input
              type="tel"
              id="phone"
              name="telefone"
              className="input"
              placeholder="Seu telefone ou whatsapp"
              onChange={({ target }) =>
                updateFormData("telefone", target.value)
              }
              value={formData.telefone}
              required
            />
          </div>
        </label>
      </div>

      <div className="field">
        <label htmlFor="email" className="label">
          E-mail
          <div className="control">
            <input
              type="email"
              id="email"
              name="email"
              className="input"
              placeholder="Seu email"
              value={formData.email}
              onChange={({ target }) => updateFormData("email", target.value)}
              required
            />
          </div>
        </label>
      </div>

      <div className="field">
        <label htmlFor="message" className="label">
          Mensagem
          <div className="control">
            <textarea
              name="mensagem"
              id="message"
              className="textarea"
              placeholder="Sua mensagem"
              rows={10}
              value={formData.mensagem}
              onChange={({ target }) =>
                updateFormData("mensagem", target.value)
              }
              required
            ></textarea>
          </div>
        </label>
      </div>

      {formResult.show && (
        <div
          className={`notification is-${
            formResult.type === "error" ? "danger" : "success"
          }`}
        >
          <button
            class="delete"
            onClick={() =>
              setFormResult({ show: false, type: null, message: null })
            }
          ></button>
          {formResult.message}
        </div>
      )}

      <div className="field has-text-centered">
        <Button type="submit">Enviar</Button>
      </div>
    </Container>
  )
}

export default Form
