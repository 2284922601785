import styled from "styled-components"

import colors from "../../styles/colors"

const lineHeight = 2

export const Container = styled.p`
  color: ${props => (props.theme === "light" ? "white" : colors.grey2)};
  font-size: ${props => (props.size === "big" ? 1.8 : 1.6)}rem;
  line-height: ${lineHeight}rem;

  &:not(:last-child) {
    margin-bottom: ${lineHeight * 2}rem;
  }
`
