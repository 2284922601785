import { FaUserMd } from "react-icons/fa"

export default {
  name: "plano-de-saude",
  label: "Plano de Saúde",
  icon: FaUserMd,
  link: "https://basisseguros.aggilizador.com.br/several",
  tags:
    "plano, saude, convênio, convenio, individual, pequenas, medias, corporativo, meidal, notre dame, amil, sul américa, america",
  shortDescription:
    "Planos de Saúde empresarias, individuais e para pequenas e médias empresas.",
  productName: "Planos de Saúde",
  features: [
    {
      icon: "group",
      title: "FAMILIAR E PME",
      text:
        "O plano de saúde familiar e para pequenas e média empresas (PME) que se encaixe em seu bolso, com atendimentos regionais, nacionais e até mesmo internacionais, tudo de acordo com a sua necessidade.",
    },
    {
      icon: "badge",
      title: "COLETIVO POR ADESÃO",
      text:
        "Para você que quer um plano individual, iremos procurar e melhor categoria de classe de acordo com a sua profissão ou formação, e o melhor custo benefício para você ficar mais tranquilo e ter um bom atendimento.",
    },
    {
      icon: "user-suite",
      title: "EMPRESARIAIS",
      text:
        "Sempre em busca do menor custo para sua empresa, e um enorme benefício para o seu colaborador com as melhores operadoras de saúde existentes no mercado.",
    },
    {
      icon: "pills",
      title: "DESCONTOS EM MEDICAMENTOS",
      text:
        "Em todos os tipos de planos, você pode contar com o benefício de desconto em medicamentos nas farmácia conveniadas ao seu plano de saúde.",
    },
  ],
}
