import { FaTruck } from "react-icons/fa"

export default {
  name: "transporte",
  label: "Transporte",
  icon: FaTruck,
  link: "https://basisseguros.aggilizador.com.br/several",
  tags:
    "transporte, carga, capotagem, inundação, queda de terra, tombamento do veículo",
  shortDescription:
    "O seguro obrigatório com coberturas para capotagem, inundação, queda de terra, tombamento do veículo. Nossos profissionais irão buscar as melhores condições e taxas para sua carga.",
  productName: "Seguro de Transporte",
  features: [
    {
      icon: "clock",
      title: "ASSISTÊNCIA 24 HORAS",
      text:
        "Em caso de acidentes rodoviários, terá toda a assistência para remoção do veículo transportador da carga em todo território nacional.",
    },
    {
      icon: "box",
      title: "AVERBAÇÕES ELETRÔNICAS",
      text:
        "A maneira rápida e fácil de fazer as averbações de suas cargas, sem perda de tempo para iniciar a viagem para que sua carga chegue ao destino no tempo combinado.",
    },
    {
      icon: "delivery",
      title: "ASSISTÊNCIA À CARGA",
      text:
        "A assistência adicional em casos de acidentes, para remoção, contenção e desobstrução da carga na pista.",
    },
    {
      icon: "bad",
      title: "PONTOS DE APOIO E DESCANSO",
      text:
        "Pontos de apoio ao motorista, para que o mesmo possa fazer sua pausa com segurança e fazer uma viagem segura.",
    },
  ],
}
