import { FaShieldAlt } from "react-icons/fa"

export default {
  name: "diversos",
  label: "Diversos",
  icon: FaShieldAlt,
  link: "https://basisseguros.aggilizador.com.br/several",
  tags:
    "diversos,notebooks, equipamentos, celulares, câmeras, responsabilidade civil profissional, responsabilidade civil de obras civis",
  shortDescription:
    "Precisa de outro tipo de seguro? Temos opções para notebooks, equipamentos, celulares, responsabilidade civil profissional, bikes e mais!",
  productName: "Seguros Diversos",
  list: [
    "Seguro para bicicletas",
    "Caminhões",
    "Táxis",
    "Seguro para Agronegócios",
    "Seguro para eventos",
    "Seguro Garantia",
    "Seguro de obras civis",
    "Seguro para terceiros",
    "Seguro odontológico",
    "Seguro de responsabilidade civil profissional",
    "Alarme e monitoramento",
    "Rastreadores",
    "Previdência Privada",
    "Cartão de crédito",
    "Consórcios",
  ],
  afterList:
    "Entre em contato com um de nossos consultores e solicite mais informações e uma cotação específica para o seu produto",
}
