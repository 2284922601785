import { createGlobalStyle } from "styled-components"

import "../../node_modules/bulma/bulma.sass"

export default createGlobalStyle`
  html {
    scroll-behavior: smooth;
    font-size: 62.5%;
  }

  body {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    padding-top: 12rem;
    
    @media (max-width: 823px) {
      padding-top: 0;
    }
    
    .container > .columns {
      padding: 0 2rem;
    }
  }
  
  
`
