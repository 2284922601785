import React, { useRef } from "react"
import { Link } from "gatsby"

import { Container, Nav } from "./styles"

import products from "../../../products"

import HeaderContext from "../context"
import { useContext } from "react"

export default function Navbar() {
  const dropDownRef = useRef(null)

  const menuItems = [
    {
      label: "Página Inicial",
      link: "/",
    },
    {
      label: "Seguros Oferecidos",
      link: "#",
      dropDown: products,
    },
    {
      label: "Sobre Nós",
      link: "/#sobre-nos",
    },
    {
      label: "Seguradoras",
      link: "/#seguradoras",
    },
    {
      label: "Contato",
      link: "/#contato",
    },
  ]

  const { showMenu } = useContext(HeaderContext)

  const renderDropDown = children => {
    return (
      <div className="navbar-dropdown">
        {children.map(child => (
          <Link key={child.name} to={`/${child.name}`} className="navbar-item">
            {child.label}
          </Link>
        ))}
      </div>
    )
  }

  const renderNavItems = item => {
    const TagName = item.dropDown ? "div" : Link

    const props = {
      className: ["navbar-item"],
    }

    if (!item.dropDown) {
      props.to = item.link
    }

    if (item.dropDown) {
      props.className.push("has-dropdown")
      props.ref = dropDownRef
      props.onClick = event => {
        event.currentTarget.classList.toggle("is-active")
      }
    }

    props.className = props.className.join(" ")

    return (
      <TagName key={item.label} {...props}>
        {!item.dropDown ? (
          item.label
        ) : (
          <div className="navbar-link is-arrowless">
            {item.label}
            {renderDropDown(item.dropDown)}
          </div>
        )}
      </TagName>
    )
  }

  return (
    <Container className={`column is-7-desktop ${showMenu ? "is-active" : ""}`}>
      <Nav className="navbar">
        <div className={`navbar-menu ${showMenu ? "is-active" : ""}`}>
          <div className="navbar-start">
            {menuItems.map(item => renderNavItems(item))}
          </div>
        </div>
      </Nav>
    </Container>
  )
}
