import React from "react"
import { FaWhatsapp } from "react-icons/fa"

import PropTypes from "prop-types"

import { Container, Header, Link } from "./styles"

import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { graphql, useStaticQuery } from "gatsby"

export default function Whatsapp({ status }) {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          whatsapp
        }
      }
    }
  `)

  const whatsapp = data.site.siteMetadata.whatsapp

  return (
    <Container status={status}>
      <Header>Atendimento por WhatsApp!</Header>

      <div>
        <Link
          href={`https://wa.me/55${whatsapp}?text=Olá, gostaria de falar com um consultor`}
          target="_blank"
          rel="noopener noreferrer"
          className="link"
          onClick={() => {
            trackCustomEvent({
              category: "WhatsApp",
              action: "Click",
            })
          }}
        >
          <FaWhatsapp />
          Fale Agora com o Consultor
        </Link>
      </div>
    </Container>
  )
}

Whatsapp.propTypes = {
  status: PropTypes.bool.isRequired,
}

Whatsapp.propDefault = {
  status: false,
}
